/* tslint:disable */
/* eslint-disable */
/**
 * Wedding
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GuestApiResult,
    GuestApiResultFromJSON,
    GuestApiResultToJSON,
    GuestIReadOnlyCollectionApiResult,
    GuestIReadOnlyCollectionApiResultFromJSON,
    GuestIReadOnlyCollectionApiResultToJSON,
    GuestInsertDto,
    GuestInsertDtoFromJSON,
    GuestInsertDtoToJSON,
    GuestUpdateDto,
    GuestUpdateDtoFromJSON,
    GuestUpdateDtoToJSON,
} from '../models';

export interface GuestGetByIdRequest {
    id: string;
}

export interface GuestGetListByIdRequest {
    id: string;
}

export interface GuestInsertRequest {
    guestInsertDto?: GuestInsertDto;
}

export interface GuestSearchRequest {
    criteria?: string;
    page?: number;
    perPage?: number;
}

export interface GuestUpdateRequest {
    guestUpdateDto?: GuestUpdateDto;
}

/**
 * 
 */
export class GuestApi extends runtime.BaseAPI {

    /**
     */
    async guestGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<GuestIReadOnlyCollectionApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Guest/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GuestIReadOnlyCollectionApiResultFromJSON(jsonValue));
    }

    /**
     */
    async guestGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GuestIReadOnlyCollectionApiResult> {
        const response = await this.guestGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async guestGetByIdRaw(requestParameters: GuestGetByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<GuestApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling guestGetById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Guest/Get/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GuestApiResultFromJSON(jsonValue));
    }

    /**
     */
    async guestGetById(requestParameters: GuestGetByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GuestApiResult> {
        const response = await this.guestGetByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async guestGetListByIdRaw(requestParameters: GuestGetListByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<GuestIReadOnlyCollectionApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling guestGetListById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Guest/GetList/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GuestIReadOnlyCollectionApiResultFromJSON(jsonValue));
    }

    /**
     */
    async guestGetListById(requestParameters: GuestGetListByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GuestIReadOnlyCollectionApiResult> {
        const response = await this.guestGetListByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async guestInsertRaw(requestParameters: GuestInsertRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<GuestApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Guest/Insert`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GuestInsertDtoToJSON(requestParameters.guestInsertDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GuestApiResultFromJSON(jsonValue));
    }

    /**
     */
    async guestInsert(requestParameters: GuestInsertRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GuestApiResult> {
        const response = await this.guestInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async guestSearchRaw(requestParameters: GuestSearchRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<GuestIReadOnlyCollectionApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.criteria !== undefined) {
            queryParameters['criteria'] = requestParameters.criteria;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Guest/Search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GuestIReadOnlyCollectionApiResultFromJSON(jsonValue));
    }

    /**
     */
    async guestSearch(requestParameters: GuestSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GuestIReadOnlyCollectionApiResult> {
        const response = await this.guestSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async guestUpdateRaw(requestParameters: GuestUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<GuestApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Guest/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GuestUpdateDtoToJSON(requestParameters.guestUpdateDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GuestApiResultFromJSON(jsonValue));
    }

    /**
     */
    async guestUpdate(requestParameters: GuestUpdateRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GuestApiResult> {
        const response = await this.guestUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
