import { defineStore } from "pinia";
import { Configuration, GuestApi, AttendeeApi } from "@/generated/";

const basePath = "https://wedding.goddards.family"

export const useStore = defineStore('store', () => {
    const config = new Configuration({ basePath: basePath });
    const guestApi = new GuestApi(config);
    const attendeeApi = new AttendeeApi(config);


    return {
        guestApi,
        attendeeApi
    }
})
