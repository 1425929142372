/* tslint:disable */
/* eslint-disable */
/**
 * Wedding
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GuestInsertDto
 */
export interface GuestInsertDto {
    /**
     * 
     * @type {string}
     * @memberof GuestInsertDto
     */
    attendeeId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GuestInsertDto
     */
    isAttendingWedding?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GuestInsertDto
     */
    isAttendingEvening?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GuestInsertDto
     */
    dietaryRequirements?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GuestInsertDto
     */
    name?: string | null;
}

export function GuestInsertDtoFromJSON(json: any): GuestInsertDto {
    return GuestInsertDtoFromJSONTyped(json, false);
}

export function GuestInsertDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuestInsertDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attendeeId': !exists(json, 'attendeeId') ? undefined : json['attendeeId'],
        'isAttendingWedding': !exists(json, 'isAttendingWedding') ? undefined : json['isAttendingWedding'],
        'isAttendingEvening': !exists(json, 'isAttendingEvening') ? undefined : json['isAttendingEvening'],
        'dietaryRequirements': !exists(json, 'dietaryRequirements') ? undefined : json['dietaryRequirements'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function GuestInsertDtoToJSON(value?: GuestInsertDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attendeeId': value.attendeeId,
        'isAttendingWedding': value.isAttendingWedding,
        'isAttendingEvening': value.isAttendingEvening,
        'dietaryRequirements': value.dietaryRequirements,
        'name': value.name,
    };
}

