/* tslint:disable */
/* eslint-disable */
/**
 * Wedding
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AttendeeApiResult,
    AttendeeApiResultFromJSON,
    AttendeeApiResultToJSON,
    AttendeeIReadOnlyCollectionApiResult,
    AttendeeIReadOnlyCollectionApiResultFromJSON,
    AttendeeIReadOnlyCollectionApiResultToJSON,
    AttendeeInsertDto,
    AttendeeInsertDtoFromJSON,
    AttendeeInsertDtoToJSON,
    AttendeeUpdateDto,
    AttendeeUpdateDtoFromJSON,
    AttendeeUpdateDtoToJSON,
} from '../models';

export interface AttendeeGetByIdRequest {
    id: string;
}

export interface AttendeeGetPageRequest {
    page?: number;
    perPage?: number;
}

export interface AttendeeInsertRequest {
    attendeeInsertDto?: AttendeeInsertDto;
}

export interface AttendeeUpdateRequest {
    attendeeUpdateDto?: AttendeeUpdateDto;
}

/**
 * 
 */
export class AttendeeApi extends runtime.BaseAPI {

    /**
     */
    async attendeeGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AttendeeIReadOnlyCollectionApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Attendee/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttendeeIReadOnlyCollectionApiResultFromJSON(jsonValue));
    }

    /**
     */
    async attendeeGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AttendeeIReadOnlyCollectionApiResult> {
        const response = await this.attendeeGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async attendeeGetByIdRaw(requestParameters: AttendeeGetByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AttendeeApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling attendeeGetById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Attendee/Get/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttendeeApiResultFromJSON(jsonValue));
    }

    /**
     */
    async attendeeGetById(requestParameters: AttendeeGetByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AttendeeApiResult> {
        const response = await this.attendeeGetByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async attendeeGetPageRaw(requestParameters: AttendeeGetPageRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AttendeeIReadOnlyCollectionApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Attendee/GetPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttendeeIReadOnlyCollectionApiResultFromJSON(jsonValue));
    }

    /**
     */
    async attendeeGetPage(requestParameters: AttendeeGetPageRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AttendeeIReadOnlyCollectionApiResult> {
        const response = await this.attendeeGetPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async attendeeInsertRaw(requestParameters: AttendeeInsertRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AttendeeApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Attendee/Insert`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AttendeeInsertDtoToJSON(requestParameters.attendeeInsertDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttendeeApiResultFromJSON(jsonValue));
    }

    /**
     */
    async attendeeInsert(requestParameters: AttendeeInsertRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AttendeeApiResult> {
        const response = await this.attendeeInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async attendeeUpdateRaw(requestParameters: AttendeeUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AttendeeApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Attendee/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AttendeeUpdateDtoToJSON(requestParameters.attendeeUpdateDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttendeeApiResultFromJSON(jsonValue));
    }

    /**
     */
    async attendeeUpdate(requestParameters: AttendeeUpdateRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AttendeeApiResult> {
        const response = await this.attendeeUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
