/* tslint:disable */
/* eslint-disable */
/**
 * Wedding
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Attendee,
    AttendeeFromJSON,
    AttendeeFromJSONTyped,
    AttendeeToJSON,
} from './Attendee';

/**
 * 
 * @export
 * @interface AttendeeIReadOnlyCollectionApiResult
 */
export interface AttendeeIReadOnlyCollectionApiResult {
    /**
     * 
     * @type {boolean}
     * @memberof AttendeeIReadOnlyCollectionApiResult
     */
    success?: boolean;
    /**
     * 
     * @type {Array<Attendee>}
     * @memberof AttendeeIReadOnlyCollectionApiResult
     */
    content?: Array<Attendee> | null;
    /**
     * 
     * @type {string}
     * @memberof AttendeeIReadOnlyCollectionApiResult
     */
    message?: string | null;
}

export function AttendeeIReadOnlyCollectionApiResultFromJSON(json: any): AttendeeIReadOnlyCollectionApiResult {
    return AttendeeIReadOnlyCollectionApiResultFromJSONTyped(json, false);
}

export function AttendeeIReadOnlyCollectionApiResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttendeeIReadOnlyCollectionApiResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': !exists(json, 'success') ? undefined : json['success'],
        'content': !exists(json, 'content') ? undefined : (json['content'] === null ? null : (json['content'] as Array<any>).map(AttendeeFromJSON)),
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function AttendeeIReadOnlyCollectionApiResultToJSON(value?: AttendeeIReadOnlyCollectionApiResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'content': value.content === undefined ? undefined : (value.content === null ? null : (value.content as Array<any>).map(AttendeeToJSON)),
        'message': value.message,
    };
}

