/* tslint:disable */
/* eslint-disable */
/**
 * Wedding
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Guest,
    GuestFromJSON,
    GuestFromJSONTyped,
    GuestToJSON,
} from './Guest';

/**
 * 
 * @export
 * @interface GuestApiResult
 */
export interface GuestApiResult {
    /**
     * 
     * @type {boolean}
     * @memberof GuestApiResult
     */
    success?: boolean;
    /**
     * 
     * @type {Guest}
     * @memberof GuestApiResult
     */
    content?: Guest;
    /**
     * 
     * @type {string}
     * @memberof GuestApiResult
     */
    message?: string | null;
}

export function GuestApiResultFromJSON(json: any): GuestApiResult {
    return GuestApiResultFromJSONTyped(json, false);
}

export function GuestApiResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuestApiResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': !exists(json, 'success') ? undefined : json['success'],
        'content': !exists(json, 'content') ? undefined : GuestFromJSON(json['content']),
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function GuestApiResultToJSON(value?: GuestApiResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'content': GuestToJSON(value.content),
        'message': value.message,
    };
}

