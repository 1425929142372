import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'


const pinia = createPinia();

const Vue = createApp(App);

Vue.use(pinia)
Vue.use(router);
Vue.mount('#app')
