/* tslint:disable */
/* eslint-disable */
/**
 * Wedding
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AttendeeInsertDto
 */
export interface AttendeeInsertDto {
    /**
     * 
     * @type {string}
     * @memberof AttendeeInsertDto
     */
    mainName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttendeeInsertDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttendeeInsertDto
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttendeeInsertDto
     */
    dietary?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AttendeeInsertDto
     */
    isInvitedToWedding?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AttendeeInsertDto
     */
    isInvitedToEvening?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AttendeeInsertDto
     */
    needsARoom?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AttendeeInsertDto
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttendeeInsertDto
     */
    songRequest?: string | null;
}

export function AttendeeInsertDtoFromJSON(json: any): AttendeeInsertDto {
    return AttendeeInsertDtoFromJSONTyped(json, false);
}

export function AttendeeInsertDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttendeeInsertDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mainName': !exists(json, 'mainName') ? undefined : json['mainName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'dietary': !exists(json, 'dietary') ? undefined : json['dietary'],
        'isInvitedToWedding': !exists(json, 'isInvitedToWedding') ? undefined : json['isInvitedToWedding'],
        'isInvitedToEvening': !exists(json, 'isInvitedToEvening') ? undefined : json['isInvitedToEvening'],
        'needsARoom': !exists(json, 'needsARoom') ? undefined : json['needsARoom'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'songRequest': !exists(json, 'songRequest') ? undefined : json['songRequest'],
    };
}

export function AttendeeInsertDtoToJSON(value?: AttendeeInsertDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mainName': value.mainName,
        'email': value.email,
        'phone': value.phone,
        'dietary': value.dietary,
        'isInvitedToWedding': value.isInvitedToWedding,
        'isInvitedToEvening': value.isInvitedToEvening,
        'needsARoom': value.needsARoom,
        'message': value.message,
        'songRequest': value.songRequest,
    };
}

